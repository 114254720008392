<template>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <header class="header-custom">
        <b-container>
          <b-row>
            <b-col cols="12" md="12" lg="12">

              <div style="width:100%;height:60px;display:flex;justify-content:flex-start;align-items:center;background-color:;">
<<<<<<< HEAD
                    <h2 style="margin:0;color:#fff">SIMKES Kabupaten Kudus</h2>
=======
                    <h2 style="margin:0;color:#fff">{{ nama_puskesmas }}</h2>
>>>>>>> 06c95613fa36574e8c4bf4ccfadcde1c3d35de69
                </div>
            </b-col>
          </b-row>
        </b-container>
      </header>
  
      <section style="padding-top: 20px; padding-bottom: 0">
        <b-container>
          <b-row>
            <b-col cols="12" md="8" lg="8">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <h2><strong>Display Poli</strong></h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <p>
                    Silahkan pilih antrian poli yang akan ditampilkan.
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
  
      <section style="background-color: whitesmoke">
        <b-container>
          <b-card>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <h2>
                  <strong><center>LIST POLI</center></strong>
                </h2>
              </b-col>
            </b-row>
  
            <b-row class="mt-2">
              <b-col v-for="item in poli" :key="item.id" cols="4" md="4" lg="4" class="mt-3">
                <b-button variant="outline-primary" size="lg" style="width:100%" @click="pilihPoli(item)">{{item.nama_poli}}</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </section>
    </div>
  </template>
  <script>
  // import axios from "axios";
  // import { ipBackend } from "@/ipBackend";
  
  export default {
    name: "master_poli_layar",
    data() {
      return {
        poli: [],
        nama_puskesmas: ""
      };
    },
    mounted() {
      this.getDatas();
      // this.testing()
      this.nama_puskesmas = localStorage.getItem('nama_puskesmas')
    },
    methods: {
      async getDatas() {
        let vm = this;
        let poli = await vm.$axios.post("/ms_poli/list");
          console.log(poli, "iki lho hasil e");
        vm.poli = poli.data.data
      },
      pilihPoli(x){
        console.log(x, 'ini x');
        this.$router.push({ path: `/display_poli/${x.ms_poli_id}` });
      },     
    },
  };
  </script>
  <style>
  .header-custom {
    width: 100%;
    height: 60px;
    /* background-color: #fff; */
    background-image: linear-gradient(to right, #2ecc71, #27ae60);

  }
  .header-custom-mobile {
    height: 100%;
    background-color: whitesmoke;
    /* height: 60px; */
  }
  .logo {
    display: flex;
    /* width: 100%; */
    height: 40px;
    align-items: center;
    justify-content: flex-end;
  }
  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .profile,
  .kantor {
    display: flex;
    flex-direction: column;
  }
  .profile span {
    margin-bottom: 2%;
  }
  .kantor span {
    margin-bottom: 2%;
  }
  </style>
  